<template>
  <div>
    <v-tabs v-model="items" style="border-bottom: 1px solid lightgray">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="items" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <component
          v-bind:is="tab.component"
          @tabmove="tabMove"
          :key="refresh"
          :refkey="refresh"
          :valid_option_category="valid_option_category"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import ShopSiteComponent from "./component/shopSite.vue";
import SiteComponent from "./component/site.vue";
import store from "@/store/index.js";
import utilFunc from "@/common/utilFunc.js";

export default {
  components: {
    ShopSiteComponent,
    SiteComponent,
  },
  setup(props, ctx) {
    const { router } = useRouter();
    const items = ref();
    const refresh = ref(1);
    const tabs = [
      {
        index: 0,
        name: "更新媒体一覧",
        component: ShopSiteComponent,
      },
      {
        index: 1,
        name: "媒体の追加",
        component: SiteComponent,
      },
    ];
    const state = reactive({
      valid_option_category: [],
    });

    const init = async () => {
      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      const currentShopData = await utilFunc.checkShopWithOption(
        currentShop.id,
        router
      );
      if (!currentShopData) return;

      // currentShopのvalid_optionを取得
      const tmp_valid_option_category = currentShopData.valid_option_category;
      if (tmp_valid_option_category != 0) {
        state.valid_option_category = tmp_valid_option_category.split(",");
      }
    };

    init();

    const tabMove = (tabIndex) => {
      items.value = tabIndex;
      refresh.value++;
    };

    return {
      items,
      tabs,
      tabMove,
      refresh,
      ...toRefs(state),
    };
  },
};
</script>
